<template>
<div class="main-container">
  <Header />

  <pdf class="pdf" v-for="i in numPages" :key="i" :src="src" :page="i" style="display: inline-block; width: 100%;height: 100%px;"></pdf>

  <BrandCarousel addClass="grey-bg" />

  <Footer />

  <OffCanvasMobileMenu />
  <!-- WhatsApp Bottom -->
  <div id="whatsapp">
    <a href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios." title="Escríbenos por Whastapp" data-tap="tooltip">
      <i class="fa fa-whatsapp" style="color: #ffffff;" aria-hidden="true"></i>
    </a>
  </div>

  <!-- back to top start -->
  <back-to-top class="scroll-top" bottom="100px" right="10px">
    <i class="ion-android-arrow-up"></i>
  </back-to-top>
  <!-- back to top end -->
</div>
</template>

<script>
import Header from "@/components/Header";
import pdf from 'vue-pdf';
import BrandCarousel from "@/components/BrandCarousel";
import Footer from "@/components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

var loadingTask = pdf.createLoadingTask('/assets/docs/terminos-y-condiciones-ruta-u-agentes.pdf');

export default {
  components: {
    Header,
    pdf,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
      src: loadingTask,
      numPages: undefined,
    };
  },
  mounted() {
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages;
    });
  }
};
</script>

<style lang="scss">
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}

#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }

  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  line-height: 35px !important;
}

.breadcrumb-area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000000;
  opacity: 0.75;
  z-index: -1;
}

.pdf canvas {
  height: auto;
}
</style>
